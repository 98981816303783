.ButtonDialog {
  padding: .5rem 3rem;
  border-radius: 8px;
  background-color: #5de8ff;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-color: transparent;
  cursor: pointer;
  box-shadow: 1px 5px 10px #272727;
}

.ButtonDialog:hover {
  background-color: #5de8ff;
  color: #116473;
  transition: 0.2s ease-in-out 0s;
}
