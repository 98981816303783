.hwdiTextAndImgContainer{
    display: flex;
    flex-direction: row;
    margin-top: 90px;
    width: 75%;
    margin-left: 12%;
    margin-bottom: 5%;
    color: #fff;
    align-items: space-between;
}

.hwdiImg{
    width: 100%; 
    height: auto;
    display: block;
}

.hwdiText{
    margin: 20px;
    font-family: var(--font500);
    /* font-family: "Hind-Siliguri"; */
    text-align: left; 
    font-size: 24px;
    color: #fff;
}

.hwdiNumber {
    width: auto; 
    height: auto; 
    aspect-ratio: 1 / 1;
    /* border: solid #00ffff 2px; */
    color: #00ffff;
    font-family: var(--font700);
    text-align: center;
}

.hwdiTextBox {
    max-width: 50%;
}

@media screen and (max-width: 1200px){
    .hwdiTextAndImgContainer{
        display: flex;
        flex-direction: column;
        margin-top: 90px;
        width: 75%;
        margin-left: 12%;
        color: #fff;
    }
    .hwdiTextBox {
        max-width: 50%;
    }

    .hwdiImg{
        width: 70%; 
        height: auto;
    }
}

@media screen and (max-width: 500px){
    .hwdiImg{
        width: 100%; 
        height: auto;
    }
    .hwdiTextBox {
        max-width: 100%;
    }
}