.boxLearningPartners--container {
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 17px;
}

.boxLearningPartners--container h1 {
  background-color: #121212;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: #fff;
  height: auto;
  text-align: center;
  font-family: var(--font700);
  font-size: 18px;
  line-height: 24px;
  padding: 11px 5px;
  border-radius: 15px 0 0 15px;
  border-left: 2px solid #0FF;
  border-right: 2px solid #0FF;
  border-top: 2px solid #0FF;
  border-bottom: 2px solid #0FF;
}

.boxLearningPartners--container h2 {
  background-color: #414141;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  color: #fff;
  height: auto;
  margin-top: 0px;
  padding: 9px 18px;
  font-family: var(--font500);
  font-size: 18px;
  line-height: 28px;
  text-align: left;
  border-top: 2px solid #0FF;
  border-bottom: 2px solid #0FF;
}

.boxLearningPartners--container h3 {
  background-color: #5de8ff;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: auto;
  font-family: var(--font500);
  border-right: 2px solid #0FF;
  border-top: 2px solid #0FF;
  border-bottom: 2px solid #0FF;
  border-radius: 0 15px 15px 0;
}

@media only screen and (max-width: 451px) and (min-width: 10px) {
  .boxLearningPartners--container {
    margin-bottom: 14px;
    padding: 0px 42px;
    flex-direction: column;
  }

  .boxLearningPartners--container h1 {
    font-size: 14px;
    line-height: 32px;
    height: 30px;
    border-radius: 15px 15px 0 0;
    border-left: 2px solid #0FF;
    border-top: 2px solid #0FF;
    border-right: 2px solid #0FF;
  }

  .boxLearningPartners--container h2 {
    padding: 14px 34px;
    font-size: 12px;
    line-height: 15px;
    border-left: 2px solid #0FF;
    border-right: 2px solid #0FF;
  }

  .boxLearningPartners--container h3 {
    font-size: 12px;
    line-height: 32px;
    border-radius: 0 0 15px 15px;
    border-left: 2px solid #0FF;
    border-right: 2px solid #0FF;
    border-bottom: 2px solid #0FF;
  }
}

@media only screen and (max-width: 576px) and (min-width: 452px) {
  .boxLearningPartners--container {
    margin-bottom: 21px;
    padding: 0px 42px;
    flex-direction: column;
  }

  .boxLearningPartners--container h1 {
    font-size: 16px;
    line-height: 32px;
    height: 48px;
    border-radius: 15px 15px 0 0;
    border-left: 2px solid #0FF;
    border-top: 2px solid #0FF;
    border-right: 2px solid #0FF;
  }

  .boxLearningPartners--container h2 {
    padding: 14px 54px;
    font-size: 16px;
    line-height: 24px;
    border-left: 2px solid #0FF;
    border-right: 2px solid #0FF;
  }

  .boxLearningPartners--container h3 {
    font-size: 16px;
    line-height: 32px;
    height: 48px;
    border-radius: 0 0 15px 15px;
    border-left: 2px solid #0FF;
    border-right: 2px solid #0FF;
    border-bottom: 2px solid #0FF;
  }
}

@media only screen and (max-width: 768px) and (min-width: 577px) {
  .boxLearningPartners--container {
    margin-bottom: 30px;
    padding: 0px 85px;
    flex-direction: column;
  }

  .boxLearningPartners--container h1 {
    font-size: 20px;
    line-height: 32px;
    height: 64px;
  }

  .boxLearningPartners--container h2 {
    padding: 10px 0px 10px 116px;
    font-size: 16px;
    line-height: 24px;
    min-height: 129px;
  }

  .boxLearningPartners--container h3 {
    font-size: 20px;
    line-height: 32px;
    height: 64px;
  }
}

@media only screen and (max-width: 992px) and (min-width: 769px) {
  .boxLearningPartners--container {
    margin-bottom: 20px;
    padding: 0px 54px;
  }

  .boxLearningPartners--container h1 {
    font-size: 18px;
    line-height: 24px;
    width: 25%;
    display: flex;
    flex-direction: column;
    padding: 11px 0px;
  }

  .boxLearningPartners--container h2 {
    font-size: 18px;
    line-height: 28px;
    width: 50%;
    font-family: var(--font400);
  }

  .boxLearningPartners--container h3 {
    font-size: 20px;
    line-height: 26px;
    width: 25%;
    text-align: center;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 993px) {
  .boxLearningPartners--container {
    margin-bottom: 20px;
    padding: 0px 13px 0px 50px;
  }

  .boxLearningPartners--container h1 {
    font-size: 19px;
    line-height: 24px;
    width: 25%;
    display: flex;
    flex-direction: column;
    padding: 11px 0px;
  }

  .boxLearningPartners--container h2 {
    font-size: 20px;
    line-height: 26px;
    width: 50%;
    font-family: var(--font400);
  }

  .boxLearningPartners--container h3 {
    font-size: 20px;
    line-height: 26px;
    width: 25%;
    text-align: center;
  }
}

@media only screen and (max-width: 1920px) and (min-width: 1281px) {
  .boxLearningPartners--container {
    margin-bottom: 20px;
    padding: 0px 13px 0px 0px;
  }

  .boxLearningPartners--container h1 {
    font-size: 19px;
    line-height: 24px;
    width: 25%;
    display: flex;
    flex-direction: column;
    padding: 11px 0px;
  }

  .boxLearningPartners--container h2 {
    font-size: 20px;
    line-height: 26px;
    width: 50%;
    font-family: var(--font400);
  }

  .boxLearningPartners--container h3 {
    font-size: 20px;
    line-height: 26px;
    width: 25%;
    text-align: center;
  }
}
