.titlePages {
  color: var(--cyan);
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 40px;
  font-family: var(--font700);
  line-height: 80px;
  margin-bottom: 53px;
}

@media only screen and (max-width: 451px) and (min-width: 10px) {
  .titlePages {
    font-size: 24px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 0px;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 576px) and (min-width: 452px) {
  .titlePages {
    font-size: 28px;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 577px) {
  .titlePages {
    font-size: 30px;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 992px) and (min-width: 769px) {
  .titlePages {
    font-size: 32px;
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 993px) {
  .titlePages {
    margin-bottom: 30px;
  }
}
