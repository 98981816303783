.surveyGeneralContainer{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.surveyImgContainer{
    width: 75%;
    height: auto;
    justify-content: center;
    align-self: center;
    margin: 3rem 0 0 0;
}

.surveyImg{
    width: 100%;
    height: auto;
    border-radius: 30px;
}

.surveyTitle{
    width: 75%;
    height: auto;
    display: flex;
    justify-content: center;
    align-self: center;
    text-align: left;
    padding: 4rem 0 0 0;
    color: #5de8ff;
    font-size: 35px;
}

.surveyText{
    width: 75%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-self: center;
    text-align: left;
    padding: 4rem 0 4rem 0;
    color: #fff;
    font-size: 24px;
    line-height: 40px;
}

@media screen and (max-width: 450px){
    .surveyTitle{
        font-size: 26px;
        padding: 2rem 0 0 0;
    }
    
    .surveyText{
        font-size: 20px;
        line-height: 30px;
        padding: 2rem 0 2rem 0;
    }
}