.ambassadorsTextAndImgContainer{
    display: flex;
    flex-direction: row;
    margin-top: 90px;
    width: 75%;
    margin-left: 12%;
    margin-bottom: 10%;
    color: #fff;
    align-items: center;
}

.ambassadorsImg{
    width: 25%; 
    height: auto;
}

.ambassadorsText{
    margin: 20px;
    font-family: var(--font500);
    /* font-family: "Hind-Siliguri"; */
    text-align: left; 
    font-size: 24px;
    color: #fff;
}

@media screen and (max-width: 1200px){
    .ambassadorsTextAndImgContainer{
        display: flex;
        flex-direction: column;
        margin-top: 90px;
        width: 75%;
        margin-left: 12%;
        color: #fff;
    }

    .ambassadorsImg{
        width: 50%; 
        height: auto;
    }
}

@media screen and (max-width: 500px){
    .ambassadorsImg{
        width: 85%; 
        height: auto;
    }
}