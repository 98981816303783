/* title  */
.partnership--box-description-text h2 {
  display: none;
  font-family: var(--font500);
}

.partnership--box-description-title {
  width: 30%;
  height: 160px;
  display: flex;
  background-color: transparent;
  background-color: #121212;
  border-top: 2px solid var(--cyan);
  border-left: 2px solid var(--cyan);
  border-bottom: 2px solid var(--cyan);
  border-radius: 15px 0 0 15px;
}

.partnership--box-description h4 {
  width: 100%;
  height: auto;
  display: flex;
  font-size: 28px;
  font-family: var(--font700);
  line-height: 20px;
  color: var(--cyan);
  /* margin-right: 50px; */
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 29px;
}

.partnership--box-description {
  width: 95%;
  display: flex;
  flex-direction: row;
  margin-bottom: 28px;
  background-color: transparent;
}

/* container description */

.partnership--box-description-text {
  width: 70%;
  height: 120px;
  font-size: 22px;
  font-family: var(--font500);
  line-height: 32px;
  color: #fff;
  background-color: #121212;
  /* background-color: rgba(255, 255, 255, 0.2); */
  border-radius: 0 15px 15px 0;
  padding: 20px 20px 20px 0;
  border-top: 2px solid var(--cyan);
  border-right: 2px solid var(--cyan);
  border-bottom: 2px solid var(--cyan);
}

.partnership--box-description-text li {
  font-size: 30px;
  font-weight: 500;
  line-height: 35px;
  color: #fff;
  background-color: #414141;
}


@media screen and (max-width: 1280px){
  /* box title  */

  .partnership--box-description {
    width: 90%;
    padding: 0 0 0 3rem;
    /* padding: 0px 64px; */
  }

  .partnership--box-description-title {
    width: 30%;
    height: 200px;
    display: flex;
    /* background-color: transparent; */
    background-color: #121212;
    border-top: 2px solid var(--cyan);
    border-left: 2px solid var(--cyan);
    border-bottom: 2px solid var(--cyan);
    border-radius: 15px 0 0 15px;
  }

  /* .partnership--box-description-title {
    width: 406px;
    height: 150px;
    margin-right: 27px;
  } */

  .partnership--box-description h4 {
    font-size: 26px;
    margin-right: 20px;
    /* width: 406px;
    height: 150px; */
  }

  /* container description */

  .partnership--box-description-text {
    width: 60%;
    height: 160px;
    font-size: 22px;
    font-family: var(--font500);
    line-height: 32px;
    color: #fff;
    background-color: #121212;
    /* background-color: rgba(255, 255, 255, 0.2); */
    border-radius: 0 15px 15px 0;
    padding: 20px 20px 20px 0;
    border-top: 2px solid var(--cyan);
    border-right: 2px solid var(--cyan);
    border-bottom: 2px solid var(--cyan);
  }

  /* .partnership--box-description-text {
    font-size: 18px;
    height: 150px;
    line-height: 24px;
    padding: 0px 10px;
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
  } */
}

@media screen and (max-width: 992px){
  /* box title  */

  .partnership--box-description-title {
    height: 160px;
  }

  /* .partnership--box-description h4 {
    width: 30%;
    height: 160px;
    font-size: 28px;
    font-family: var(--font700);
    line-height: 20px;
    color: var(--cyan);
    background-color: #121212;
    /* margin-right: 50px; */
    /* border-radius: 15px 0 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 29px;
    border-top: 2px solid var(--cyan);
    border-left: 2px solid var(--cyan);
    border-bottom: 2px solid var(--cyan);
  } */

  .partnership--box-description h4 {
    font-size: 18px;
    margin-right: 20px;
    /* width: 206px; */
  }

  /* container description */

  .partnership--box-description-text {
    height: 120px;
    font-size: 18px;
    line-height: 20px;
    /* padding: 0px 10px; */
    display: flex;
    align-items: center;
    align-content: center;
  }
}

@media screen and (max-width: 768px){
  .partnership--box-description{
    width: 90%;
  }
  
  .partnership--box-description-title {
    font-size: 12px;
    height: 120px;
  }

  /* container description */

  .partnership--box-description-text {
    height: 80px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1px;
  }

  .partnership--box-description h4 {
    font-size: 16px;
    margin-right: 15px;
  }
}

@media screen and (max-width: 576px) {
  .partnership--box-description-title {
    display: none;
  }

  /* title mobile */
  .partnership--box-description-text h2 {
    display: block;
    padding-bottom: 20px;
    margin: 0 1rem 0 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    min-width: 120px;
  }

  /* container description */

  .partnership--box-description {
    justify-content: center;
    padding: 0;
    margin: 1rem 1rem 1rem 2rem;
  }

  .partnership--box-description-text {
    width: 80%;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    padding: 1rem;
    height: 160px;
    border: 2px solid var(--cyan);
    border-radius: 15px;
  }
}

@media screen and (max-width: 451px){
  .partnership--box-description {
    justify-content: center;
    padding: 0;
    margin: 1rem 1rem 1rem 1.5rem;
  }
  /* title desktop */

  .partnership--box-description-title {
    display: none;
  }

  .partnership--box-description-text {
    width: 80%;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    padding: 1rem;
    height: auto;
    border: 2px solid var(--cyan);
    border-radius: 15px;
  }

  /* title mobile */
  .partnership--box-description-text h2 {
    display: block;
    padding-bottom: 26px;
    margin: 0px;
    font-size: 18px;
    line-height: 20px;
  }

  /* container description */

  .partnership--box-description {
    justify-content: center;
  }

  /* .partnership--box-description-text {
    background-color: rgba(255, 255, 255, 0.2);
    font-size: 11px;
    line-height: 15px;
    text-align: left;
    padding: 24px 12px;
    width: 280px;
  } */
}

@media screen and (max-width: 360px){

  .partnership--box-description-text {
    width: 80%;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    padding: 1rem;
    height: auto;
    border: 2px solid var(--cyan);
    border-radius: 15px;
  }
}