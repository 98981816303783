.licensing--container {
  margin-bottom: 150px;
}

.licensing--subtitle h3 {
  padding: 0px 100px;
  font-size: 25px;
  font-family: var(--font500);
  line-height: 32px;
  text-align: center;
  color: #fff;
}

.licensing--subtitle h4 {
  margin-top: 30px;
  font-size: 15px;
  font-family: var(--font500);
  line-height: 32px;
  text-align: center;
  color: #fff;
}

/* //image 1 chart */

.licensing--image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.licensing--chart--1 {
  width: 60%;
  height: auto;
}

/* image 2 field  */
.licensing--chart--2 {
  width: 75%;
  height: auto;
  margin-top: 130px;
}

.licensing--chart-container-2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

/* / two fields  */

.licensing--container-fields-2-3 {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 140px;
}

.licensing--content-fields-2-3 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.licensing--content-fields-2-3 p {
  color: #00ffff;
  font-size: 30px;
  font-family: var(--font700);
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 40px;
}

.licensing--container-fields-2-3 img {
  width: 90%;
}

/* promotion & advertising */

.licensing--promotion-advertising {
  width: auto;
  margin: 80px 100px 100px 100px;
  border-radius: 19px;
  background-color: #116473;
  display: flex;
  justify-content: center;
  color: #fff;
  align-items: center;
  padding: 20px 140px;
}

.licensing--promotion-advertising img {
  height: auto;
}

.licensing--promotion-advertising h2 {
  font-size: 30px;
  font-family: var(--font700);
  line-height: 32px;
  text-align: center;
  margin-bottom: 19px;
}

.licensing--promotion-advertising h3 {
  font-size: 20px;
  font-family: var(--font500);
  line-height: 28px;
  text-align: center;
}

/* investor ecosystem */

.licensing--investor-ecosystem {
  padding: 0px 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 172px;
}

.licensing--investor-ecosystem h2 {
  font-size: 30px;
  font-family: var(--font700);
  line-height: 32px;
  text-align: center;
  color: var(--cyan);
  margin-bottom: 30px;
}

.licensing--investor-ecosystem h3 {
  font-size: 20px;
  font-family: var(--font400);
  line-height: 32px;
  text-align: center;
  color: #fff;
  margin-bottom: 76px;
}

.licensing--investor-img-mb {
  display: none;
}

.licensing--investor-img-desktop {
  width: 97%;
  height: auto;
}

.licensing--span {
  box-shadow: -138px 119px 293px 108px rgb(43 167 167 / 40%);
  box-shadow: #00ffff;
  height: 8rem;
  width: 0rem;
  left: 20%;
  position: absolute;
  z-index: 0;
}

.licensing--span--2 {
  box-shadow: -138px 119px 293px 108px rgb(43 167 167 / 40%);
  box-shadow: #00ffff;
  height: 8rem;
  width: 0rem;
  left: 110%;
  position: absolute;
  z-index: 0;
}

@media only screen and (max-width: 451px) and (min-width: 10px) {
  .licensing--container {
    margin-bottom: 80px;
  }

  /* subtitle */
  .licensing--subtitle {
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .licensing--subtitle h3 {
    padding: 0px;
    font-size: 14px;
    line-height: 15px;
    max-width: 287px;
  }

  .licensing--subtitle h4 {
    margin-top: 18px;
    font-size: 12px;
    line-height: 15px;
    max-width: 287px;
  }

  /* image chart */
  .licensing--chart--1 {
    width: 80%;
  }

  /* image 2 field  */
  .licensing--chart--2 {
    width: 93%;
    margin-top: 44px;
  }

  /* / two fields  */

  .licensing--container-fields-2-3 {
    margin-top: 30px;
    flex-direction: column;
  }

  .licensing--content-fields-2-3 {
    margin-bottom: 20px;
  }

  .licensing--content-fields-2-3 p {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 14px;
    display: flex;
    align-content: flex-start;
    justify-content: center;
    height: 40px;
  }

  /* promotion & advertising */

  .licensing--promotion-advertising {
    margin: 10px 0px 15px 0px;
    border-radius: 0px;
    padding: 25px 50px;
  }

  .licensing--promotion-advertising img {
    display: none;
  }

  .licensing--promotion-advertising h2 {
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 20px;
  }

  .licensing--promotion-advertising h3 {
    font-size: 12px;
    font-family: var(--font500);
    line-height: 15px;
  }

  /* investor ecosystem */

  .licensing--investor-ecosystem {
    padding: 0px;
    margin: 0px;
  }

  .licensing--investor-img-desktop {
    display: none;
  }

  .licensing--investor-img-mb {
    display: block;
    height: auto;
    width: 90%;
  }

  .licensing--investor-ecosystem h2 {
    font-size: 14px;
    line-height: 32px;
    margin-bottom: 0px;
  }

  .licensing--investor-ecosystem h3 {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 10px;
    padding: 16px 15%;
  }
}

@media only screen and (max-width: 576px) and (min-width: 452px) {
  .licensing--container {
    margin-bottom: 90px;
  }

  .licensing--subtitle {
    padding: 0px 40px;
  }

  .licensing--subtitle h3 {
    padding: 0px;
    font-size: 16px;
    line-height: 15px;
  }

  .licensing--subtitle h4 {
    margin-top: 20px;
    font-size: 14px;
    line-height: 15px;
  }

  /* image chart */
  .licensing--chart--1 {
    width: 80%;
  }

  /* image 2 field  */
  .licensing--chart--2 {
    width: 85%;
    margin-top: 20px;
  }

  /* / two fields  */

  .licensing--container-fields-2-3 {
    margin-top: 28px;
  }

  .licensing--content-fields-2-3 p {
    font-size: 16px;
    max-width: 80%;
    line-height: 20px;
    margin-bottom: 14px;
    display: flex;
    align-content: flex-start;
    justify-content: center;
    height: 40px;
  }

  /* promotion & advertising */

  .licensing--promotion-advertising {
    margin: 34px 0px;
    border-radius: 0px;
    padding: 20px 50px;
  }

  .licensing--promotion-advertising img {
    display: none;
  }

  .licensing--promotion-advertising h2 {
    font-size: 18px;
    line-height: 19px;
    margin-bottom: 20px;
  }

  .licensing--promotion-advertising h3 {
    font-size: 14px;
    line-height: 18px;
  }

  /* investor ecosystem */

  .licensing--investor-ecosystem {
    padding: 0px;
    margin-bottom: 0px;
  }

  .licensing--investor-img-desktop {
    display: none;
  }

  .licensing--investor-img-mb {
    display: block;
    height: auto;
    width: 85%;
    margin-top: 10px;
  }

  .licensing--investor-ecosystem h2 {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 0px;
  }

  .licensing--investor-ecosystem h3 {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 0px;
    padding: 20px 15%;
  }
}

@media only screen and (max-width: 768px) and (min-width: 577px) {
  .licensing--subtitle {
    padding: 0px 40px;
  }

  .licensing--subtitle h3 {
    padding: 0px;
    font-size: 18px;
    line-height: 22px;
  }

  .licensing--subtitle h4 {
    margin-top: 20px;
    font-size: 16px;
    line-height: 19px;
  }

  /* image chart */
  .licensing--chart--1 {
    width: 70%;
  }

  /* image 2 field  */
  .licensing--chart--2 {
    margin-top: 30px;
  }

  /* / two fields  */

  .licensing--container-fields-2-3 {
    margin-top: 80px;
  }

  .licensing--content-fields-2-3 p {
    font-size: 20px;
    max-width: 80%;
    line-height: 20px;
    margin-bottom: 20px;
    display: flex;
    align-content: flex-start;
    justify-content: center;
    height: 40px;
  }

  /* promotion & advertising */

  .licensing--promotion-advertising {
    margin: 50px 0px;
    border-radius: 0px;
    padding: 25px 50px;
  }

  .licensing--promotion-advertising img {
    display: none;
  }

  .licensing--promotion-advertising h2 {
    font-size: 19px;
    font-family: var(--font700);
    line-height: 20px;
    margin-bottom: 20px;
  }

  .licensing--promotion-advertising h3 {
    font-size: 16px;
    font-family: var(--font500);
    line-height: 18px;
  }

  /* investor ecosystem */

  .licensing--investor-ecosystem {
    padding: 0px;
    margin-bottom: 0px;
  }

  .licensing--investor-img-desktop {
    margin-top: 20px;
    width: 90%;
  }

  .licensing--investor-ecosystem h2 {
    font-size: 20px;
    line-height: 21px;
    margin-bottom: 0px;
  }

  .licensing--investor-ecosystem h3 {
    font-size: 18px;
    line-height: 19px;
    margin-bottom: 20px;
    padding: 20px 48px;
  }
}

@media only screen and (max-width: 992px) and (min-width: 769px) {
  .licensing--container {
    margin-bottom: 106px;
  }

  .licensing--subtitle {
    padding: 0px 76px;
  }

  .licensing--subtitle h3 {
    padding: 0px;
    font-size: 20px;
    line-height: 20px;
  }

  .licensing--subtitle h4 {
    margin-top: 20px;
    font-size: 18px;
    line-height: 20px;
  }

  /* image chart */
  .licensing--chart--1 {
    width: 70%;
  }

  /* image 2 field  */
  .licensing--chart--2 {
    margin-top: 25px;
    width: 85%;
  }

  /* / two fields  */

  .licensing--container-fields-2-3 {
    margin-top: 80px;
  }

  .licensing--content-fields-2-3 p {
    font-size: 26px;
    max-width: 80%;
    line-height: 28px;
    margin-bottom: 40px;
    display: flex;
    align-content: flex-start;
    justify-content: center;
    height: 40px;
  }

  /* promotion & advertising */

  .licensing--promotion-advertising {
    margin: 50px;
    padding: 20px 80px;
  }

  .licensing--promotion-advertising img {
    height: 100px;
    margin-right: 20px;
  }

  .licensing--promotion-advertising h2 {
    font-size: 19px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .licensing--promotion-advertising h3 {
    font-size: 16px;
    line-height: 20px;
  }

  /* investor ecosystem */

  .licensing--investor-ecosystem {
    padding: 0px;
    margin-bottom: 0px;
  }

  .licensing--investor-ecosystem h2 {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 0px;
  }

  .licensing--investor-ecosystem h3 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 0px;
    padding: 20px 60px;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 993px) {
  .licensing--subtitle h3 {
    padding: 0px;
    font-size: 26px;
    line-height: 30px;
    padding: 0px 115px;
  }

  .licensing--subtitle h4 {
    margin-top: 20px;
    font-size: 22px;
    line-height: 30px;
  }

  .licensing--chart--1 {
    width: 70%;
  }

  .licensing--chart--2 {
    margin-top: 50px;
  }
}
