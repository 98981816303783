.carrusel-container {
  display: flex;
  overflow: hidden;
  width: 90%;
  position: relative;
  box-sizing: border-box;
  margin-left: 60px;
  margin-right: 60px;
}

.escudos-wrapper {
  
  display: flex;
  animation: scroll 300s linear infinite;
}

.escudo {
  height: 120px;
  margin-right: 60px; 
  object-fit: contain; 
}

@keyframes scroll {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(calc(-90% / 3));
  }
}


