.contentGeneralContainer{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0;
}

.carouselGeneralContainer{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 5% 0;
}

.buttonsContainer{
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-self: center;
    /* padding: 2rem 0; */
}

.buttons{
    width: 15%;
    min-width: 230px;
    min-height: 70px;
    margin: 5% 2% 5% 2%;
    padding: 1rem 1rem;
    border: solid #00ffff; 
    border-radius: 10px;
    background: none;
    color: #fff;
    cursor: pointer;
    font-family: var(--font700);
    font-size: 21px;
}

.buttons:hover{
    background-color: #00ffff;
    color: #000;
}

.buttons:active{
    background-color: #00ffffb6;
    color: #000;
}

.carousel{
    width: 70%;
    height: auto;
    display: flex;
    justify-content: center;
    align-self: center;
}

.imagesContainer{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}

.images{
    width: 100%;
    height: auto;
    justify-content: center;
    
}

.preventiveSurveyContainer{
    display: flex;
    flex-direction: row;
    width: 80%;
    height: auto;
    margin: 8rem 1rem 1rem 1rem;
    padding: 2rem 0 0 0;
    border: solid #00ffff;
    border-radius: 15px;
    justify-content: center;
    align-self: center;
}

.preventiveSurveyTextContainer{
    display: flex;
    flex-direction: column;
    width: 80%;
    height: auto;
    padding: 2rem;
}

.preventiveSurveyTitle{
    width: 100%;
    height: auto;
    color: #00ffff;
    font-size: 26px;
    font-family: var(--font500);
}

.preventiveSurveyText{
    width: 100%;
    height: auto;
    color: #fff;
    font-size: 20px;
    padding: 2rem 0 0 0;
    font-family: var(--font500);
}

.preventiveSurveyImgContainer{
    width: 20%;
    height: auto;
    display: flex;
    place-items: end;
    margin: 1% 15% 0 0;
}

.preventiveSurveyImg{
    /* width: 376px; */
    /* height: 253px; */
    width: 474px;
    height: 319px;
}

/* .howWeDoItImg{
    width: 90%;
    height: auto;
    // margin-left: 20%;
    // margin-top: 50px;
} */

/* CAROUSEL ARROWS */
.slick-prev:before{
    font-size: 35px !important;
    margin: 0 0 0 -2.5rem !important;
}

.slick-next:before {
  font-size: 35px !important;
  margin: 0 0 0 1rem !important;
}

.slick-dots {
    width: 100% !important;
    height: auto !important;
    bottom: -40px !important;
}

.slick-dots li button:before {
    color: rgba(255, 255, 255, 0.7) !important;
}

.slick-dots li.slick-active button:before {
    color: #00ffff !important;
}


@media screen and (max-width: 1600px){
    .preventiveSurveyTextContainer{
        display: flex;
        flex-direction: column;
        width: 75%;
        height: auto;
        padding: 2rem;
    }

    .preventiveSurveyImgContainer{
        width: 30%;
        height: auto;
        display: flex;
        place-items: end;
        margin: 1% 10% 0 0;
    }
}

@media screen and (max-width: 1500px){
    .buttonsContainer{
        width: 80%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        /* padding: 2rem 0; */
    }

    .buttons{
        width: 20%;
        margin: 3%;
        padding: 1rem 2rem;
        font-size: 18px;
    }
    
}

@media screen and (max-width: 1360px){
    .preventiveSurveyContainer{
        display: flex;
        flex-direction: column;
        width: 80%;
        height: auto;
        margin: 8rem 1rem 1rem 1rem;
        padding: .5rem 0 0 0;
        border: solid #00ffff;
        border-radius: 15px;
        justify-content: center;
        align-self: center;
    }

    .preventiveSurveyTextContainer{
        width: 90%;
        padding: 1rem;
        align-self: center;
    }

    .preventiveSurveyImgContainer{
        align-self: center;
    }

    .preventiveSurveyImg{
        margin: 0;
    }
}

@media screen and (max-width: 1090px){
    .preventiveSurveyContainer{
        display: flex;
        flex-direction: column;
        width: 80%;
        height: auto;
        margin: 8rem 1rem 1rem 1rem;
        padding: 2rem 0 0 0;
        border: solid #00ffff;
        border-radius: 15px;
        justify-content: center;
        align-self: center;
    }

    .preventiveSurveyTextContainer{
        align-self: center;
    }

    .preventiveSurveyImgContainer{
        align-self: center;
    }

    .preventiveSurveyImg{
        margin: 0 0 0 0;
    }
    
}

@media screen and (max-width: 850px){
    .buttons{
        width: 25%;
        margin: 5%;
        padding: 1rem 2rem;
    }
    
    .preventiveSurveyImgContainer{
        width: 30%;
        height: auto;
        display: flex;
        place-items: end;
        margin: 1% 40% 0 0;
    }
}

@media screen and (max-width: 650px){
    .buttons{
        width: 35%;
        min-width: 250px;
        margin: 5%;
        padding: 1rem 2rem;
        font-size: 18px;
    }

    .carousel{
        width: 80%;
        margin: 2rem 0 0 0;
    }

    .preventiveSurveyImgContainer{
        width: 100%;
        height: auto;
        text-align: center;
        padding: 1% 0 0 0;
    }

    .preventiveSurveyImg{
        width: 70%;
        height: auto;
        padding: 1% 0 0 35%;
    }

    .slick-prev:before{
        display: none !important;
    }
    
    .slick-next:before {
        display: none !important;
    }
}

@media screen and (max-width: 450px){
    .buttons{
        width: 50%;
        min-width: 250px;
        margin: 5%;
        padding: 1rem 2rem;
        font-size: 18px;
    }

    .preventiveSurveyImgContainer{
        width: 100%;
        height: auto;
        text-align: center;
        padding: 1rem 1rem 0 1rem;
    }
    
    .preventiveSurveyTitle{
        font-size: 21px;
    }

    .preventiveSurveyText{
        font-size: 16px;
    }

    .slick-dots {
        bottom: -60px !important;
    }
}