.buttonPrimary {
  height: 49px;
  width: 154px;
  border-radius: 16px;
  padding: 12px 16px;
  background-color: transparent;
  border: 2px solid #197888;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.buttonPrimary p {
  text-align: center;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  line-height: 32px;
  font-family: var(--font600);
}

.buttonPrimary:hover {
  transition: var(--transition);
  background-color: #5de8ff;
  border-color: 1px solid #00ffff;
}

.buttonPrimary:hover p {
  color: black;
  transition: var(--transition);
}

@media only screen and (max-width: 451px) and (min-width: 1px) {
  .buttonPrimary {
    height: 25px;
    width: 94px;
    border-radius: 8px;
    border: 2px solid var(--cyan);
  }

  .buttonPrimary p {
    font-size: 12px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 576px) and (min-width: 452px) {
  .buttonPrimary {
    height: 28px;
    width: 94px;
    border-radius: 6px;
    border: 2px solid var(--cyan);
  }

  .buttonPrimary p {
    font-size: 12px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 577px) {
  .buttonPrimary {
    height: 42px;
    width: 119px;
    font-size: 16px;
    line-height: 20px;
    border: 2px solid var(--cyan);
  }
}

@media only screen and (max-width: 992px) and (min-width: 769px) {
  .buttonPrimary {
    height: 31px;
    width: 100px;
    border-radius: 10px;
  }

  .buttonPrimary p {
    font-size: 16px;
  }
}
