.makeStyles-list-1 {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #121212;
}

.MuiDrawer-paperAnchorLeft {
  width: 70%;
}

.span-menu {
  box-shadow: -39px 283px 293px 108px rgb(43 167 167 / 40%);
  height: 8rem;
  width: 0rem;
  left: 5rem;
  top: 5rem;
  position: absolute;
  z-index: 0;
}

.button-close {
  display: flex;
  justify-content: flex-end;
  padding: 2rem 2rem 3rem 2rem;
}

@media only screen and (max-width: 451px) and (min-width: 10px) {
  .MuiDrawer-paperAnchorLeft {
    width: 100%;
  }
}

@media only screen and (max-width: 992px) and (min-width: 769px) {
  .MuiDrawer-paperAnchorLeft {
    width: 50%;
  }
}

@media only screen and (max-width: 1920px) and (min-width: 993px) {
  .MuiDrawer-paperAnchorLeft {
    width: 0%;
  }

  .MuiBackdrop-root {
    opacity: 0;
    transition: none;
  }

  .span-menu {
    display: none;
  }
}
