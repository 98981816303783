.container {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 0 5%;
    align-items: center;
    gap: 20px;
    color: #ffffff
}

.inputLine {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}
.container input {
    width: 15rem;
    height: 2.5rem;
    border: solid 2px #00ffff;
    color: #ffffff;
    background-color: #000000;
    padding-left: 5px;
}

.container label {
    font-weight: 600;
    color: #ffffff;
}

.container p {
    font-weight: 400;
    color: #cacaca;
}

.buttonDiv {
    width: 15rem;
    height: 2.5rem;
    background-color: #00ffff;
    color: #000000;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
}

.container input[type=radio] {
    width: 20px;
    height: 20px;
    border: solid 2px #00ffff;
    color: #ffffff;
    background-color: #000000;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.footer {
    width: 100%;
    display: flex;
    justify-content: center;
}